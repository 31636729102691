import React from "react"
import { Container, useTheme, Button, Divider } from "@mui/material"
import LoginIcon from '@mui/icons-material/Login';
import { NavigationItem } from "./navigationItem"


const MainNavigation = (props) => {
  const theme = useTheme()
   
  const { handleCloseNavMenu, menu } = props

  return (
    <Container sx={{ pt: { xs: 1, sm: 1, md: 0 }, pb: { xs: 11, sm: 11, md: 0 }, marginTop: { xs: 0, sm: 0, md: 0 }, width: { xs: '100%', sm: '100%', md: 'auto', lg: '62%' }, flexBasis: { xs: '100%', sm: '100%', md: 'auto', lg: '62%' }, flexGrow: 1, order: { xs: 0, sm: 0, md: 0, lg: 0 }, display: { sm: 'flex' }, flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'flex-start', md: 'flex-end', lg: 'flex-end' } }}>
      <Divider sx={{ mb: theme.spacing(8) }} />
      {menu && menu?.map((menuItem, i) => {

        return (
          <NavigationItem
            handleCloseNavMenu={handleCloseNavMenu}
            key={`main-menu-item-${i}`}
            link={menuItem?.link?.link}
            childNode={menuItem?.childItems}
            text={menuItem?.link?.text}
            to={menuItem?.link}
          />
        )
      })
      }
      <Button
        href="https://bookings.gemapark.co.uk/Secure/AccountAreaLogin.aspx?cid=335&pid=13258&_gl=1*iyopoc*_gcl_au*MTkxMzk3ODUwOC4xNzA4NTA5NjMx"
        variant="text"
        color="tertiary"
        size="large"
        endIcon={<LoginIcon />}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          textAlign: { xs: 'left', md: 'center' },
          justifyContent: { xs: 'left', sm: 'center' },
          fontWeight: 400,
          px: theme.spacing(1),
         }}>Manage Booking

      </Button>


    </Container>
  )
}

export default MainNavigation