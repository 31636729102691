import groq from 'groq'

// * Helpful - https://www.sanity.io/docs/query-cheat-sheet

export const PAGE_BUILDER = `
pageBuilder[] {
  ...,
  video{...},
  faqs[]->{...},
  formTerms {
    ...,
    link{
      internal->,
      external{...},
      file{
        asset->,
      },
    },
  },
  headerTile[] {
    ...,
    link{
      ...,
      link{
        ...,
        internal->,
        file{
          asset->,
        },
      },
    },
  },
  backgroundColour{ 
    designSystemColor->{...},
  },
  highlighted,
  images[] { 
    asset->,
    hotspot{...},
    crop{...}
  },
  image {
    asset->,
    hotspot{...},
    crop{...}
  },
  links[]{
    link{
      internal->{
        ...,
        slug {
          current
        }
      },
      external,
      file{
        asset->{
          ...,
        },
      },
    },
    text,
  },
  subtitle,
  title[]{...},
  text[]{...},
  smallText[]{...},
  title,
  text,
  navColor->,
  overlay,
  topPadding,
  leftAlign, 
  steps[]{
    ...,
    title,
    description,
    involves,
    _type, 
  },
  showArchive{
    ...,
    archive[]->{...},  
  },
  showCaseStudyArchive{
    ...,
    archive[]->{
      ..., 
    },
  },
  author->{...},
  servicesTile[]{
    ...,
    text,
    link{
      ...,
      link{
        internal->,
        external,
        file{
          asset->,
        },
      },
    },
  },
  featuresTile[]{
    ...,
    text,
    link{
      ...,
      link{
        internal->,
        external,
        file{
          asset->,
        },
      },
    },
  },
  subItems[]{...},
  overlayColour->{...},
  testimonialTiles[]{...},
  searchColour->{...},
}`
export const SITE_SETTINGS = groq`*[_type == "siteSettings"] {
  ...,
  text[]{...},
  ballot{
    ballotUrl{
      ...,
      link{
        internal->{
          ...,
          slug {
            current
          }
        },
        external{...},
        file{
          asset->{
            ...,
          },
        },
      },
    }
  }
}`

export const NAV_QUERY = groq`*[_type == "navigation" && navId.current == $navId ]{
  ...,
  items[]{
    ...,
    childItems[]{
      link{
        internal->{
          ...,
          slug {
            current
          }
        },
        external{...},
        file{
          asset->{
            ...,
          },
        },
      },
      text,
    },
  }
}`

export const POST_QUERY = groq`*[_type == "post" && slug.current == $slug][0] {
  ...,
  ${PAGE_BUILDER},
  title,
  text,
  date,
  author->{...},
  image {
    asset->,
    hotspot{...},
    crop{...}
  },
  navColor{...},
  tileColor{...},
  mobileImage {
    asset->,
    hotspot{...},
    crop{...}
  },
  backgroundColor->,
  textAlign,
  slug,
  companyDetails,
  person,
  _rawPerson,
  coverImage {
    asset->,
    hotspot{...},
    crop{...}
  },
  body[]{...},
  _id,
}`

export const ALL_CASE_STUDIES = groq`*[_type == "caseStudy"] {
  _id,
  title,
  ...,
  service->{name},
}`

export const CASE_STUDIES_BY_ID = groq`*[_type == "caseStudy" && references($categoryId)] {
  _id,
  ...,
  service->{name},
}`


export const ALL_POSTS = groq`*[_type == "post"] {
  _id,
  title,
  ...,
  category->{...},
  "image" :  coverImage {
    asset->,
    hotspot{...},
    crop{...}
  },
}`

export const POSTS_BY_ID = groq`*[_type == "post" && references($categoryId)] {
  _id,
  ...,
  category->{...},
  "image" : coverImage {
    asset->,
    hotspot{...},
    crop{...}
  },
}`

export const PAGE_QUERY = groq`*[_type == "page" && slug.current == $slug][0] {
  ...,
  ${PAGE_BUILDER},
  title,
  text,
  image {
    asset->,
    hotspot{...},
    crop{...}
  },
  navColor{navColor->, ...},
  mobileImage {
    asset->,
    hotspot{...},
    crop{...}
  },
  textAlign,
  slug->,
  companyDetails,
  _rawPerson,
  coverImage {
    asset->,
    hotspot{...},
    crop{...}
  },
  _id,  
}`