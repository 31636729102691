import React from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
import { Button, Box } from "@mui/material"

export const ButtonFormat = props => {
  const { node, sx, endIcon, variant, color, size = "large" } = props

  let definedInternal = node?.link?.internal?.slug?.current
  const definedExternal = node?.link?.external
  const definedFile = node?.link?.file

  if (node?.link?.internal?._type === "post") {
    definedInternal = `blog/${definedInternal}`
  }
  if (node?.link?.internal?._type === "unit") {
    definedInternal = `holiday-homes/${definedInternal}`
  }
  let linkComponent = "";

    if(node?.link?.internal !== null){

      if(!definedInternal?.includes("search-results/")){

        linkComponent = (
          <GatsbyButton
            size={size}
            color={color}
            variant={variant}
            to={`/${definedInternal}`} 
            endIcon={endIcon}
          >
            {node?.text}
          </GatsbyButton>
        )
      }
      else{
        linkComponent = (
          <Button
            size={size}
            color={color}
            variant={variant}
            href={`/${definedInternal}`}
            endIcon={endIcon}
          >
            {node?.text}
          </Button>
        )
      }
        
    }
    if (node?.link?.file) {
      linkComponent = (
        <Button
          size={size}
          color={color}
          variant={variant}
          href={definedFile?.asset?.url}
          endIcon={endIcon}
        >
          {node?.text}
        </Button>
      )
    }
    if(node?.link?.external !== null){
      linkComponent = (
        <Button
          size={size}
          color={color}
          variant={variant}
          href={definedExternal}
          rel="noreferrer"
          target="_blank"
          endIcon={endIcon}
        >
          {node?.text}
        </Button>
      )
    }

  return <Box sx={sx}>{linkComponent}</Box>
}
